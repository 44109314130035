header {
    position: fixed;
    z-index: 999;
    padding: 35px 0;
    width: 100%;
    top: 0;
    transition: top 0.3s;

    @include below ($s) {
        display: none;
    }

    &.nav-up {
        top: -115px;
    }

}

.nav-bg {
    background-color: $white;

    &.white {
        background-color: $blue;
    }
}

.nav {
    max-width: 80%;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include below ($s) {
        display: none;
    }

    &-inner {

        &.white {

            ul {
                li {
                    a {
                        color: $white !important;

                        &:after {
                            background-color: $white !important;
                        }
                    }
                }
            }

        }

        .menu-menu-1-container, .menu {
            ul {
                li {
                    display: inline-block;
                    margin-right: 50px;

                    @include below ($s) {
                        margin-right: 20px;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    a {
                        font-size: 16px;
                        font-family: $book;
                        letter-spacing: 2px;
                        color: $blue;
                        &:after {
                            visibility: hidden;
                        }

                        &:hover {
                            &:after {
                                visibility: visible;
                            }
                        }
                    }
                }

                .current-menu-item {
                    a {
                        &:after {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.logo {
    &:after {
        display: none;
    }

    img {
        @include below ($s) {
            width: 30%;
        }
    }
}