#services {
    padding: 200px 0 150px; 

    @include below ($m) {
        padding: 100px 0 0; 
    }

    @include below ($m) {
        padding: 80px 0 0; 
    }
}
.services {
    position: relative;

    .flex {
        margin-bottom: 200px; 

        @include below ($m) {
            margin-bottom: 100px;
        }

        &:last-of-type {
            margin-bottom: 0;
            .flex__first-column {
                padding-top: 0px;
            }
        }

        &:first-child {
            .flex__first-column {
                padding-top: 150px;
            }
        }


        &:nth-child(even) {
            .flex__first-column {
                order: 2;

                @include below ($m) {
                    order: 0;
                }
            }

        }

        &__second-column {
            img {
                box-shadow: 0 2px 30px 0 rgba(0,0,0,0.10);
                width: 100%;
            }

            @include below ($m) {
                margin-top: 100px;
            }

            @include below ($s) {
                margin-top: 50px;
            }
        }

        &__first-column {
            max-width: 460px; 

            @include below ($m) {
                max-width: 100%;
            } 

            .icon {
                margin-bottom: 35px;
            }

        }
    }

    &-info {
        position: absolute; 
        top: 0; 
        left: 0;

        .icon {
            vertical-align: top; 
            margin-right: 25px;
        }

        .services-inner-text {
            display: inline-block;
            font-size: 14px; 
            line-height: 19px;

            h3, p  {
                font-size: 14px; 
                line-height: 19px;
            }
            
            p {
                font-family: $medium;
            }

            h3 {
                font-family: $bold;
            }
        }
    }
}

.service-columns {
    margin-top: 150px;

    @include below ($m) {
        margin-top: 100px;
    }

    @include below ($m) {
        margin: 100px 0 80px;
    }
}

.service-column {
    width: 49%;
    float: left;
    margin-bottom: 30px; 

    &:nth-child(odd) {
        margin-right: 17px;

        @include below ($s) {
            margin-right: 0; 
        }
    }

    @include below ($m) {
        width: 48%; 
    }

    @include below ($s) {
        width: 100%; 
    }
}