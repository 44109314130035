.hero {
    min-height: 800px;
    background-size: cover; 
    background-position: center center;
    position: relative;

    @include below ($m) {
        min-height: 600px;
    }

    @include below ($s) {
        min-height: 500px;
    }

    &__inner {
        max-width: 940px; 
        width: 100%;
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
        text-align: center;

        &.about {
            top: 30%; 

            @include below (350px) {
                top: 40%;
            }
        }

        @include below ($m) {
            max-width: 90%; 
        }
    }

    &__link-holder {
        margin-top: 40px; 
        @include below ($s) {
            margin-top: 20px;
        }
    }
}

.about-anchor {
    max-width: 485px; 
    margin: 0 auto;
    display: flex; 
    justify-content: space-between;
    margin-top: 40px;

    @include below ($s) {
        margin-top: 20px;
    }

    @include below (350px) {
        flex-direction: column; 
        margin-top: 30px;
    }

    a {
        color: $blue;
        letter-spacing: 2px;
        overflow: visible;
        &:after {
            visibility: hidden;
        }
        &:hover {
            &:after {
                visibility: visible;
            }
        }

        @include below (350px) {
            margin-bottom: 20px; 

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}