.start-text-img {
    padding: 200px 0;

    @include below ($m) {
        padding: 100px 0;
    }

    .flex {
        margin-bottom: 200px; 

        @include below ($m) {
            margin-bottom: 100px; 
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:nth-child(even) {
            .flex__first-column {
                order: 2;

                @include below ($m) {
                    order: 0;
                }
            }
        }

        &__second-column {

            @include below ($m) {
                margin-top: 100px;
            }

            @include below ($s) {
                margin-top: 40px;
            }

            img {
                box-shadow: 0 2px 30px 0 rgba(0,0,0,0.10);
                width: 100%;
            }
        }

        &__first-column {
            max-width: 450px; 

            @include below ($m) {
                max-width: 100%; 
            }

            .icon {
                margin-bottom: 35px;

                @include below ($s) {
                    margin-bottom: 20px;    
                }
            }

            p {
                margin-bottom: 35px;
            }
        }
    }
}