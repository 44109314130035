footer {
    background-color: $blue; 
    color: #8087A6;
    padding: 100px 100px 200px;

    @include below ($s) {
        padding: 50px 50px 150px; 
    }

    .footer-column {
        display: inline-block;
        vertical-align: top;

        &:first-of-type {
            margin-right: 80px;

            @include below ($s) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        a {
            display: block;
            color: #8087A6;
            font-family: $regular;
            text-decoration: none;
            text-transform: none;
            transform: all .2s ease-in-out;
            letter-spacing: 0;

            &:hover {
                color: $white;
            }

            &:after {
                display: none;
            }
        }

        p, a {
            font-size: 16px;
            line-height: 30px;
        }
    }
}