.text-half-img {
    min-height: 800px; 
    width: 100%;

    @include below ($m) {
        min-height: 600px;
    }

    @include below ($s) {
        min-height: 500px;
    }

    .flex {
        @include below ($s) {
            flex-direction: column;
        }
    }

    .flex-slide-column {
        @include below ($s) {
            min-height: 400px;
            width: 100%;
        }
    }
}