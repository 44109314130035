.mobile-nav {
    display: none;
    width: 90%;
    margin: 0 auto;

    @include below ($s) {
        display: block;
    }

    &-container {
        position: fixed;
        width: 100%;
        z-index: 99999;
        padding: 25px 0 20px;
        background-color: $white;

        @include above ($s) {
            display: none;
        }
    }

    &__inner {
        width: 0;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        background-color: $blue;
        transition: all .2s ease-in-out;
        &.open {
            width: 285px;

            .menu-menu-1-container, .menu {
                left: 50%;
                right: auto;
            }
        }

        .menu-menu-1-container, .menu {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            right: -370px;

            ul {
                li {
                    list-style: none;
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    a {
                        color: $white;
                        font-size: 25px;
                        text-transform: none;
                        font-family: $book;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    a {
        display: inline-block;
        width: 110px;

        img {
            width: 100%;
        }
    }

    .hamburger {
        display: inline-block;
        width: 30px;
        height: 23px;
        position: relative;
        vertical-align: top;
        float: right;
        z-index: 9999;

        &.open {
            span {
                background-color: $white;
                opacity: 0;

                &:first-of-type {
                    opacity: 1;
                    transform: rotate(-45deg);
                }

                &:last-of-type {
                    opacity: 1;
                    transform: rotate(45deg);
                    top: 0;
                }
            }
        }

        span {
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            background-color: $blue;
            transform: translateY(-50%);
            transition: all .2s ease-in-out;

            &:first-of-type {
                top: 0;
                transform: none;
            }

            &:last-of-type {
                bottom: 0;
                top: auto;
                transform: none;
            }
        }
    }
}

body {
    &.fixed {
        position: fixed;
    }
}