//fonts

@font-face {
    font-family: 'Avenir Next Regular';
    src: url('../fonts/AvenirNext-Regular.ttf') format('truetype'),
    url('../fonts/AvenirNext-Regular.eot') format('eot'),
    url('../fonts/AvenirNext-Regular.svg') format('svg'),
    url('../fonts/AvenirNext-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Demi Bold';
    src: url('../fonts/AvenirNext-DemiBold.ttf') format('truetype'),
    url('../fonts/AvenirNext-DemiBold.eot') format('eot'),
    url('../fonts/AvenirNext-DemiBold.svg') format('svg'),
    url('../fonts/AvenirNext-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: 'Avenir Next Medium';
    src: url('../fonts/AvenirNext-Medium.ttf') format('ttf'),
    url('../fonts/AvenirNext-Medium.eot') format('eot'),
    url('../fonts/AvenirNext-Medium.svg') format('svg'),
    url('../fonts/AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: bold;
}

@font-face {
    font-family: 'Franklin Gothic';
    src: url('../fonts/Franklin-Gothic.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src: url('../fonts/FranklinGothic-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src: url('../fonts/FranklinGothic-Book-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

$bold: 'Franklin Gothic';
$regular: 'Franklin Gothic';
$medium: 'Franklin Gothic';
$book: 'Franklin Gothic Book';

//colors
$white: #FFF;
$green: #8BA7AB;
$blue: #2A376B;
$underlineBlue: #8087A6;
$beige: #F7F3EE;
$sand: #E7E1E3;

//text-colors

.c-white {
    color: $white;
}

.c-blue {
    color: $blue;
}


//text

.title-xl {
    font-family: $regular;
    font-size: 100px;
    line-height: 105px;

    @include below ($m) {
        font-size: 60px;
        line-height: 80px;
    }

    @include below ($s) {
        font-size: 38px;
        line-height: 50px;
    }
}

.title-l {
    font-family: $regular;
    font-size: 55px;
    line-height: 65px;

    @include below ($m) {
        font-size:45px;
        line-height: 55px;
    }
}

.title-m {
    font-family: $regular;
    font-size: 40px;
    line-height: 50px;

    @include below ($m) {
        font-size: 35px;
        line-height: 45px;
    }

    @include below ($s) {
        font-size: 30px;
        line-height: 40px;
    }
}

.p-large {
    font-family: $book;
    font-size: 28px;
    line-height: 38px;

    @include below ($m) {
        font-size: 24px;
        line-height: 34px;
    }

    @include below ($s) {
        font-size: 19px;
        line-height: 30px;
    }
}

.p-reg {
    font-family: $book;
    font-size: 26px;
    line-height: 36px;

    @include below ($m) {
        font-size: 22px;
        line-height: 32px;
    }

    @include below ($s) {
        font-size: 18px;
        line-height: 30px;
    }
}

p {
    font-family: $book;
    font-size: 18px;
    line-height: 26px;
}

a {
    font-family: $book;
    font-size: 17px;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;
    overflow-x: hidden;
    transition: transform 250ms ease-in-out;
    letter-spacing: 2px;

    @include below ($s) {
        font-size: 16px;
    }

    &:hover {
        &:after {
            transform: scaleX(0);
        }
    }

    &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: $blue;
        position: absolute;
        bottom: -5px;
        left: 0;
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%;
    }

    &.white-line {
        &:after {
            background-color: $white;
        }
    }
}

//background

.bg-green {
    background-color: $green;
}

.bg-beige {
    background-color: $beige;
}

.bg-sand {
    background-color: $sand;
}

.bg-blue {
	background-color: $blue;
}

//grids

.wrapper {
    max-width: 940px;
    margin: 0 auto;
    position: relative;

    @include below ($m) {
        width: 90%;
    }
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.column-m {
    @include below ($m) {
        flex-direction: column;
    }
}

//extras

.blue-line {
    width: 60px;
    height: 2px;
    background-color: $blue;
    display: block;
    margin: 35px 0;

    @include below ($m) {
        margin: 25px 0;
    }

    &.line-middle {
        margin: 35px auto;
        @include below ($m) {
            margin: 25px auto;
        }
    }

    &.long {
        margin: 10px 0;
        width: 200px;
    }
}

.icon {
    @include below ($s) {
        width: 50px;
        height: 50px;
    }
}