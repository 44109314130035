.contact {
    padding: 200px 0 140px;

    @include below ($m) {
        padding: 100px 0;
    }

    @include below ($s) {
        padding: 80px 0;
    }
 
    &-info {
        max-width: 600px;
        margin-bottom: 60px;

        img {
            margin-right: 20px;
        }
        &__inner {
            display: inline-block;
            vertical-align: top;

            h3 {
                font-family: $bold;
                font-size: 14px; 
                line-height: 19px;

            }
            a {
                display: block;
                font-family: $medium;
                font-size: 14px;
                line-height: 19px;
                text-transform: none;
                color: $blue;
                letter-spacing: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
}

.employee {
    max-width: 380px;
    float: left; 
    margin-bottom: 60px;
    width: 100%; 

    &:nth-child(odd) {
        margin-right: 180px;
        @include below ($m) { 
            margin-right: 10px;
        }
        @include below ($s) { 
            margin-right: 0;
        }
    }

    &:last-of-type {
        @include below ($s) {
            margin-bottom: 0;
        }
    }

    @include below ($m) {
        max-width: 49%; 
    }

    @include below ($s) {
        max-width: 100%; 
    }

    &__card {
        width: 100%;
        position: relative;

        img {
            float: right;
        }

        &-text {
            position: absolute;
            background-color: $sand;
            bottom: 0;
            left: 0;
            padding: 10px 10px 10px 0;

            h5 {
                font-family: $bold;
                font-size: 17px; 
                text-transform: uppercase;
                letter-spacing: 1.5px;
            }

            p {
                font-size: $regular; 
                font-size: 18px; 
                line-height: 25px; 
            }
            a {
                display: block;
                font-family: $regular;
                font-size: 18px;
                line-height: 25px;
                text-transform: none;
                color: $blue;
                letter-spacing: 0;
                &:after {
                    display: none;
                }
            }
        }
    }

    &__text {
        p {
            font-family: $bold; 
            font-size: 14px; 
            line-height: 19px;
        }
    }
}