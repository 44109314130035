.clients {
    text-align: center;
    padding: 120px;

    @include below ($m) {
        padding: 100px; 
    }

    @include below ($s) {
        padding: 80px 0; 
        width: 90%; 
        margin: 0 auto;
    }

    &__inner {
        max-width: 885px; 
        margin: 0 auto;

        p, h3{
            max-width: 625px;
            margin: 0 auto;
        }

        p {
            margin-bottom: 50px;
        }
    }

    &-logo-holder {
    width: 25%;
    float: left;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
        @include below ($m) {
            width: 50%;
        }
        @include below($s) {
            width: 100%;

            img {
                max-height: 100%; 
            }
        }
    }
}