.js-slider {
    min-height: 800px;
    height:100%;
    position: relative;
    overflow-x: hidden;

    @include below ($m) {
        min-height: 600px;
    }

    @include below ($s) {
        min-height: 500px;
    }

    &:focus {
        outline: 0;
    }
}

.flickity-slider {
    min-height: 800px;
    height:100%;
    width: 100%; 

    @include below ($m) {
        min-height: 600px;
    }

    @include below ($s) {
        min-height: 500px;
    }
}

.slide-background {

    background-size: cover;
    min-height: 800px;
    height:100%;
    width: 100%;

    @include below ($m) {
        min-height: 600px;
    }

    @include below ($s) {
        min-height: 500px;
    }
}

.slider-holder {
    min-height: 800px;
    height:100%;
    width: 80%; 
    background-size: cover;
    background-position: center center;
    cursor: grab;

    @include below ($m) {
        width: 90%;
        min-height: 600px;
    }

    @include below ($s) {
        min-height: 500px;
        width: 100%;
    }

}

//temporary solution for showing people in startpage slider
.start-slider-holder {
    &:nth-child(4n) {
        @include below ($s) {
            background-position: center left;
        }
    }

    &:nth-child(5n) {
        @include below ($s) {
            background-position: center right;
        }
    }
}

.slider-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 500px;
    font-family: $bold; 
    font-size: 14px;
    padding: 30px 40px;
    background-color: rgba(255, 255, 255, 0.6);

    @include below ($s) {
        padding: 20px 20px 55px;
        max-width: 80%;
    }

    h3 {
        font-size: 17px; 
        line-height: 23px;
        text-transform: uppercase;
    }

    h4 {
        margin-bottom: 20px;
    }
}

.slider__inner {
    max-width: 750px; 
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    @include below ($m) {
        max-width: 90%;
    }

    &.half-img {
        text-align: left; 
        max-width: 460px;

        @include below ($m) {
            max-width: 90%; 
        }
    }

    .icon {
        margin-bottom: 40px;

        @include below ($s) {
            margin-bottom: 10px; 
        }
    }

    p {
        margin-bottom: 50px;

        @include below ($s) {
            margin-bottom: 10px; 
            font-size: 16px;
            line-height: 30px;
        }
    }

    h3 {
        margin-bottom: 5px;
    }

    .line-middle {
        margin: 15px auto;
    }

    .title-m {
        @include below ($m) {
        font-size: 22px; 
        line-height: 32px;
        }
    }
}

.flickity-button {

    &.previous {
        display: none; 
    }

    &.next {
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        opacity: 0;

        @include below ($m) {
            width: 10%; 
        }

        @include below ($s) {
            display: none;
        }
    }
}

.flickity-page-dots {
    counter-reset: a;
    list-style: none;
    padding-left: 0;
    position: absolute;
    bottom: 30px;
    margin: 0;
    left: 50%; 
    transform: translateX(-50%); 
}

.dot {

    display: inline-block;
    margin-right: 10px; 
    cursor: pointer; 
    height: 12px;
    width: 12px; 
    border: 1px solid $white; 
    border-radius: 50%;


    &:last-of-type {
        margin-right: 0;
    }

    &.is-selected {
        background-color: $white;
    }
}

.flex-slide-column {
    width: 50%;
    background-size: cover;
    min-height: 800px;
    position: relative;
    background-position: center center;

    @include below ($m) {
        min-height: 600px;
    }
    @include below ($s) {
        min-height: 500px;
    }
}