.start-text-block {
    padding: 207px 0;
    position: relative;

    @include below ($m) {
        padding: 150px 0;
    }

    @include below ($s) {
        padding: 80px 0;
    }

    &__inner {
        max-width: 780px;
        margin: 0 auto;
        text-align: center;

        @include below ($m) {
            max-width: 90%;
        }

        h2 {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 40px;
        }

        .icon {
            margin-bottom: 40px;

            @include below ($s) {
                margin-bottom: 20px;
            }
        }

		a {
			.bg-blue & {
				&:after {
					background-color: $underlineBlue;
				}
			}
		}

        h6 {
            margin-bottom: 30px;
            @include below ($s) {
                font-size: 30px;
                line-height: 45px;
            }
        }
    }
}